/*==========================================================================
Skill
==========================================================================*/
.skill__area {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 190px;
    &-left {
        &-play-icon {
            position: relative;
            background: $white;
            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
            display: inline-block;
            border-radius: 50%;  
            a {
                color: $text-color-1;
                font-size: 25px;  
                display: block;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 3;
    
            }  
        }
    }    
    &-right {
        background: $text-color-1;
        padding: 50px 40px;
        margin-left: 50px;
        margin-bottom: -160px;
        margin-top: 85px;
        &-skill {
            &-item {
                margin-top: 30px;
                &-content {
                    position: relative;
                    h6 {
                        font-size: 16px;
                        line-height: 26px;
                        font-weight: 500;
                    }
                }
                &-count {
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: $white;
                    font-weight: 500;
                }
                &-bar {
                    .skillbar-bar {
                        height: 8px;
                        margin: 3px;
                        left: 0;
                        background: transparent;
                        background-image: linear-gradient(-45deg, $text-color-3 25%, transparent 25%, transparent 60%, $text-color-3 50%, $text-color-3 75%, transparent 75%, transparent);
                        background-size: 16px 20px;
                        border-radius: 0;
                    }
                    .skillbar {
                        margin: 0;
                        margin-top: 10px;
                        border: 1px solid $text-color-3;
                    }
                    .skillbar-percent,
                    .skillbar-title {
                        display: none;
                    }
                }
            }
        } 
    }
}
/*==========================================================================
Skill Twp
==========================================================================*/
.skill__area-two {
    background: $text-color-1;
    &-left {
        position: relative;
        padding-top: 10px;
        img {
            filter: grayscale(1);
            display: inline-block;
        }
        span {
            color: $white;
            position: absolute;
            top: 50%;
            font-size: 120px;
            line-height: 130px;
            text-transform: uppercase;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 0.5px;
            font-weight: 700;
            width: 100%;
            font-family: $heading-font;
            opacity: 0.1;
            transform: translateY(-50%);
        }
    }    
    &-right {
        &-skill {
            &-item {
                &-content {
                    position: relative;
                    h6 {
                        font-size: 16px;
                        line-height: 26px;
                        color: $text-color-4;
                        font-weight: 500;
                        font-family: $body-font;
                    }
                }
                &-count {
                    position: absolute;
                    top: 0;
                    right: 0;
                    font-family: $body-font;
                    color: $text-color-4;
                    font-weight: 500;
                }
                &-bar {
                    .skillbar-bar {
                        height: 4px;
                        background: $text-color-4;
                        left: -1px;
                        border-radius: 0;
                        top: -2px;
                    }
                    .skillbar {
                        margin: 0;
                        margin-top: 10px;
                        border: 2px solid $text-color-6;
                        height: 4px;
                    }
                    .skillbar-percent,
                    .skillbar-title {
                        display: none;
                    }
                }
            }
        } 
    }
}
@media (max-width: 1199px) {
    .skill__area {
        padding: 150px 0;  
        &-left {
            &-play-icon {
                top: 118px;
            }
        } 
        &-right {
            margin: 0;
        }
    }
    .skill__area-two {
        &-left {
            span {
                font-size: 90px;
                line-height: 100px;
            }
        }
    }
}
@media (max-width: 991px) {
    .skill__area-two {
        padding: 60px 0;
    }
    .skill__area {
        &-left {
            &-play-icon {
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}
@media (max-width: 510px) {
    .skill__area-two {
        &-left {
            span {
                font-size: 60px;
                line-height: 70px;
            }
        }
    }
    .skill__area {
        &-right {
            padding: 50px 30px;
        }
    }
}
@media (max-width: 347px) {
    .skill__area-two {
        &-left {
            span {
                font-size: 54px;
                line-height: 64px;
            }
        }
    }
}