$body-font: 'Inter', sans-serif;
$heading-font: 'Ubuntu', sans-serif;
$body-color: #CFD0D0;
$white: #FFFFFF;
$primary-color: #01019d;
$primary-color-1: #01019d;
$text-color-1: #030404;
$text-color-2: #3F4144;
$text-color-3: #6F7173;
$text-color-4: #9FA0A1;
$text-color-5: #F8F8F8;
$text-color-6: #17181A;
$text-color-7: #0F1212;
$text-color-8: #121717;
$text-color-9: #6B91B0;
$border: #1E2323;
$border1: #101111;
$border2: #1D1E1E;
$border3: #272A2A;
$border4: #0C0F0F;
$shadow-1:rgba(0, 0, 0, 0.1);
$shadow-2:rgba(0, 0, 0, 0.07);
$shadow-3:0 0 60px rgb(255 255 255 / 7%);